.coming-soon {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.coming-soon h1 {
  font-size: 6em;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: -5px;
  line-height: 80px;
}

.coming-soon h3 {
  color: #fff;
  margin-top: 1rem;
}
