footer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer p {
  color: #fff;
}
