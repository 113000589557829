@font-face {
  font-family: "Liberation Sans Regular";
  src: url(../../Assets/Fonts/LiberationSans-Regular.ttf);
}

@font-face {
  font-family: "Liberation Sans Bold";
  src: url(../../Assets/Fonts/LiberationSans-Bold.ttf);
}

@font-face {
  font-family: "Gotham Black";
  src: url(../../Assets/Fonts/Gotham-Black.otf);
}

.header-nav {
  position: fixed;
  padding: 1rem;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.header-nav .header-logo {
  background-color: #fff;
  margin-left: 1rem;
}

.header-nav ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.header-nav ul > li {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  cursor: pointer;
  height: 100%;
  font-weight: bolder;
}

.header-nav .header-buttons {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.header-nav .header-logo a {
  color: #000;
  text-decoration: none;
  font-family: "Gotham Black";
}

.header-nav .nav-buttons {
  color: #fff;
  font-family: "Liberation Sans Regular";
}

.header-nav .nav-buttons > a {
  color: #fff;
  text-decoration: none;
  font-family: "Liberation Sans Regular";
}

.header-nav .nav-buttons .dropdown-menu {
  position: absolute;
  margin-top: 0rem;
  margin-left: -1rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  opacity: 1;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s, margin-top 0.5s;
}

.dropdown-menu.active {
  opacity: 1 !important;
  margin-top: 6rem !important;
  z-index: 0 !important;
}

.header-nav .nav-buttons .dropdown-menu li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #000;
  font-size: small;
}

.header-nav .nav-buttons .dropdown-menu a {
  text-decoration: none;
  color: #000;
}

.header-nav .nav-buttons .dropdown-menu span {
  margin-left: 1rem;
}

.chevron,
.chevron-active {
  pointer-events: none;
  margin-left: 0.5rem;
}

.has-dropdown {
  display: flex;
  align-items: center;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .header-nav {
    width: 100%;
    padding: 0; /* Remove padding to maximize space */
  }

  .header-nav ul {
    width: 100%;
    justify-content: center;
    padding-top: 1rem;
  }

  .header-nav .header-logo {
    margin-right: 1rem;
    text-align: center;
  }

  .header-nav ul > li {
    margin: 0.5rem;
    padding: 0.7rem 1rem;
    font-size: smaller;
    text-align: center;
  }

  .header-nav .nav-buttons .dropdown-menu {
    width: 50%;
    margin: 0;
  }
}
