.image-trail-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-trail-container.grid-display {
  margin: 0 auto;
  width: 90vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 5rem;
}

.image-trail-container img {
  transition: opacity 0.1s ease;
}

.image[data-status="inactive"] {
  opacity: 0.5;
}

.image[data-status="active"] {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .image-trail-container.grid-display {
    height: fit-content;
    padding-top: 10rem;
    padding-bottom: 5rem;
    gap: 20px;
  }

  .image[data-status="inactive"] {
    opacity: 1;
  }
}
