body,
html {
  height: auto;
  padding: 0;
  margin: 0;
  background-color: #232323;
  /* overflow-x: hidden; */
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  opacity: 0;
}

section {
  width: 100vw;
  height: 100vh;
}

#main-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@font-face {
  font-family: "HelveticaNow-Regular";
  src: url("./Assets/Fonts/HelveticaNowDisplay-Regular.ttf");
}

@font-face {
  font-family: "LiberationSans-Bold";
  src: url("./Assets/Fonts/LiberationSans-Bold.ttf");
}

@font-face {
  font-family: "LiberationSans-Regular";
  src: url("./Assets/Fonts/LiberationSans-Regular.ttf");
}

h1 {
  font-family: "LiberationSans-Bold";
}

h2 {
  font-family: "LiberationSans-Regular";
}

h3,
li,
p,
a,
span {
  font-family: "HelveticaNow-Regular";
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #0000;
  border-right-color: #ffa50097;
  position: relative;
  animation: l24 1s infinite linear;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -8px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}
.loader:after {
  animation-duration: 4s;
}
@keyframes l24 {
  100% {
    transform: rotate(1turn);
  }
}
