.about-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 998;
  transition: all 0.3s ease;
}

.about-container {
  position: absolute;
  height: fit-content;
  margin: 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 1000;
  top: 4em;
  left: 0;
}

.columns-container {
  display: flex;
  flex-direction: row;
}

.hidden {
  pointer-events: none;
  opacity: 0;
}

.show {
  opacity: 1;
}

.about-container .left-column {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 10px;
  margin-right: 50px;
  width: 50%;
}

.about-container .right-column {
  width: 50%;
  box-sizing: border-box;
  overflow: hidden; /* Prevents any content from extending beyond the column */
}

.about-container .right-column .contact-container {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border-radius: 10px;
}

.about-container .left-column .intro-text-container {
  margin-bottom: 10vh;
}

.about-container .left-column h3 {
  font-family: "HelveticaNow-Regular";
  font-weight: lighter;
  font-size: 2em;
  margin-top: 0;
}

.about-container .left-column h2 {
  font-family: "LiberationSans-Bold";
  letter-spacing: -0.6px;
  font-size: 1.5em;
  margin-top: 0;
  text-transform: uppercase;
}

.about-container .left-column .select-clients-container {
  padding-bottom: 10vh;
  padding-left: 5vh;
}

.about-container .left-column ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  padding-left: 0;
  font-family: "HelveticaNow-Regular";
  width: auto;
  column-gap: 20px;
}

.about-container .left-column ul > li {
  margin-bottom: 0.5rem;
}

.about-container .close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin: 0.5rem; /* Adjust margin for X spacing */
}

.video-reel-container {
  margin-top: 2rem;
  margin-bottom: 2rem; /* Adds some space below the video */
  padding: 0 1rem; /* Adds padding to ensure the content doesn't touch the edges */
  width: auto; /* Ensures the container takes the necessary width only */
  overflow: hidden; /* Prevents any content from overflowing */
}

@media (max-width: 768px) {
  .about-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-container {
    position: relative;
    width: 80vw;
    height: 70vh;
    margin: 0 auto;
    padding: 1rem;
    overflow-y: scroll;
    margin-top: -2rem;
  }

  .about-container .columns-container {
    flex-direction: column;
  }

  .about-container .columns-container .left-column {
    margin: 0 auto;
    width: 90%;
    margin-top: 2.5rem;
  }

  .about-container .columns-container .left-column .intro-text-container h3 {
    font-size: 1.5em;
  }

  .about-container .left-column .select-clients-container {
    padding-left: 0;
  }

  .about-container .columns-container .right-column {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 2.5rem;
  }

  .about-container
    .columns-container
    .right-column
    .video-reel-container
    .react-player {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-container
    .columns-container
    .right-column
    .video-reel-container
    .react-player
    > div {
    height: auto;
  }

  .about-container
    .columns-container
    .right-column
    .video-reel-container
    .react-player
    iframe {
    width: 100%;
  }

  .video-reel-container {
    padding: 0;
  }
}
